// ListingsTable.tsx

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { ListingResponse } from '../../../../api/src/listings/listing-response.interface' // adjust the import path if necessary
import { Alert } from '@mui/lab';
import { Box, CircularProgress } from '@mui/material';
import { differenceInDays, differenceInHours, differenceInMinutes, parseISO } from 'date-fns';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { selectSources } from '../../features/source/sourceSlice';
import { useSelector } from 'react-redux';


interface ListingsTableProps {
    listings: ListingResponse[];
    onRowClick: (row: any) => void;
    loading: boolean;
    error: string | null;
}

export const ListingsTable: React.FC<ListingsTableProps> = ({ listings, loading, error, onRowClick }) => {
    const sources = useSelector(selectSources);
    
    if (error) {
        return (
            <Alert severity="error">{error}</Alert>
        );
    }

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    const columns = [
        { field: 'title', headerName: 'Title', flex: 3},
        { field: 'districtName', headerName: 'District', flex: 2},
        { field: 'postalCode', headerName: 'Postal Code', flex: 1},
        { field: 'sqm', headerName: 'Sqm', flex: 1 },
        { field: 'price', headerName: 'Price (€)', flex: 1 },
        { field: 'rooms', headerName: 'Rooms', flex: 1 },
        {
            field: 'publishedAt',
            headerName: 'Published At',
            flex: 2,
            valueGetter: (params: GridValueGetterParams) => {
                const publishedDate = parseISO(params.value);
                const now = new Date();

                const diffInDays = differenceInDays(now, publishedDate);
                if (diffInDays > 0) return `${diffInDays} day(s) ago`;

                const diffInHours = differenceInHours(now, publishedDate);
                if (diffInHours > 0) return `${diffInHours} hour(s) ago`;

                const diffInMinutes = differenceInMinutes(now, publishedDate);
                return `${diffInMinutes} minute(s) ago`;
            }
        },
        {
            field: 'sourceName',
            headerName: 'Source',
            flex: 2,
            valueGetter: (params: GridValueGetterParams) => {
                return sources.find(source => source.id === params.row.sourceId)?.slug
            }
        },
    ];

    return (
        <div style={{ height: "auto", width: '100%' }}>
            <DataGrid
                rows={listings}
                columns={columns}
                onRowClick={(param) => onRowClick(param.row)}
            />
        </div>
    );
};
