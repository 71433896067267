// src/app/store.ts

import { configureStore } from '@reduxjs/toolkit';
import listingReducer from '../features/listing/listingSlice';
import sourceReducer from '../features/source/sourceSlice';
import districtReducer from '../features/district/districtSlice';

export const store = configureStore({
    reducer: {
        listings: listingReducer,
        sources: sourceReducer,
        districts: districtReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
