// src/features/user/listingSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchListings } from './listingAPI';
import { RootState } from '../../app/store';
import { ListingResponse } from '../../../../api/src/listings/listing-response.interface'
import { FetchListingsParams } from './listingAPI';

export const fetchListingsAsync = createAsyncThunk(
    'listing/fetchListings',
    async (params: FetchListingsParams) => {
        const response = await fetchListings(params);
        return response;
    }
);

export const listingSlice = createSlice({
    name: 'listings',
    initialState: {
        objects: [] as ListingResponse[],
        error: null as null | string,
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchListingsAsync.fulfilled, (state, action) => {
                state.objects = action.payload; // Corrected line
                state.loading = false;
            })
            .addCase(fetchListingsAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchListingsAsync.rejected, (state, action) => {
                state.error = action.error.message || 'Unknown error occurred';
            });
    },
});

export default listingSlice.reducer;

export const selectListings = (state: RootState) => state.listings.objects;
export const selectError = (state: RootState) => state.listings.error;
export const selectLoading = (state: RootState) => state.listings.loading;