// src/features/user/listingSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSources } from './sourceAPI';
import { RootState } from '../../app/store';
import { Source } from './sourceAPI';

export const fetchSourcesAsync = createAsyncThunk(
    'sources/fetchSources',
    async () => {
        const response = await fetchSources();
        return response;
    }
);

export const sourceSlice = createSlice({
    name: 'sources',
    initialState: {
        objects: [] as Source[],
        error: null as null | string,
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSourcesAsync.fulfilled, (state, action) => {
                state.objects = action.payload; // Corrected line
                state.loading = false;
            })
            .addCase(fetchSourcesAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchSourcesAsync.rejected, (state, action) => {
                state.error = action.error.message || 'Unknown error occurred';
            });
    },
});

export default sourceSlice.reducer;

export const selectSources = (state: RootState) => state.sources.objects;
export const selectError = (state: RootState) => state.sources.error;
export const selectLoading = (state: RootState) => state.sources.loading;