// Navbar.tsx

import React from 'react';
import { AppBar, Toolbar, Container, Box } from '@mui/material';
// import { Link } from 'react-router-dom';

export const Navbar: React.FC = () => (
    <AppBar position="static" style={{ background: '#FFFFF5' }}>

        <Toolbar>
            <Container>
                <Box my={2}>
                    <img src="/header.png" alt="logo" style={{ height: "50px" }} /> 
                </Box>

            </Container>
            {/* <Typography variant="h6" style={{ flexGrow: 1 }}>
                FlatWizard
            </Typography> */}
            {/* <Button color="inherit" component={Link} to="/listings">
                Listings
            </Button> */}
        </Toolbar>
    </AppBar>
);
