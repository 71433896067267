// ListingsContainer.tsx

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/hooks';
import { fetchListingsAsync, selectListings, selectError, selectLoading } from '../../features/listing/listingSlice';
import { ListingsTable } from './ListingsTable';
import { ListingFilter } from './ListingsFilter';
import { FetchListingsParams } from '../../features/listing/listingAPI';
import { ListingDetails } from './ListingDetails';

import {Box, Typography} from '@mui/material';

export const ListingsContainer: React.FC = () => {
    const dispatch = useAppDispatch();
    const listings = useSelector(selectListings);
    const error = useSelector(selectError);
    const loading = useSelector(selectLoading);

    const [selectedListing, setSelectedListing] = useState(null);

    const [open, setOpen] = useState(false); // New state to control modal visibility

    const handleRowClick = (row: any) => {
        setSelectedListing(row);
        setOpen(true); // Open the modal when a row is clicked
    };

    const applyFilters = (params: FetchListingsParams) => {
        dispatch(fetchListingsAsync(params));
    };

    useEffect(() => {
        const initialParams: FetchListingsParams = JSON.parse(localStorage.getItem('filters') || '{}');
        dispatch(fetchListingsAsync(initialParams));
    }, [dispatch]);

    // pass the listings to the presentational component
    return (
        <Box sx={{ my: 2 }}>
            <Typography variant="h4" align="left" gutterBottom>
                Latest Listings:
            </Typography>
            <ListingFilter onApply={applyFilters} />
            <ListingsTable listings={listings} onRowClick={handleRowClick} loading={loading} error={error} />
            {selectedListing && <ListingDetails listing={selectedListing} open={open} handleClose={() => { setSelectedListing(null); setOpen(false); }} />}
        </Box>
    );
};
