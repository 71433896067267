// App.tsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { ListingsContainer } from './components/listings/ListingsContainer';
import { Container, Box } from '@mui/material';
import { fetchSourcesAsync } from './features/source/sourceSlice';
import { fetchDistrictsAsync } from './features/district/districtSlice';
import { useAppDispatch } from './app/hooks';
import { Analytics } from '@vercel/analytics/react';

function App() {
  const dispatch = useAppDispatch();
  dispatch(fetchSourcesAsync());
  dispatch(fetchDistrictsAsync());


  return (
    <>
      <Router>
        <Navbar />
        <Container>
          <Box my={2}>
            <Routes>
              <Route path="/" element={<ListingsContainer />} />
            </Routes>
          </Box>
        </Container>
        <Footer />
      </Router>
      <Analytics />
    </>
  );
}

export default App;
