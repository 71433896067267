// src/features/user/listingSlice.ts

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchDistricts } from './districtAPI';
import { RootState } from '../../app/store';
import { District } from './districtAPI';

export const fetchDistrictsAsync = createAsyncThunk(
    'listing/fetchDistricts',
    async () => {
        const response = await fetchDistricts();
        return response;
    }
);

export const districtSlice = createSlice({
    name: 'districts',
    initialState: {
        objects: [] as District[],
        error: null as null | string,
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDistrictsAsync.fulfilled, (state, action) => {
                state.objects = action.payload;
                state.loading = false;
            })
            .addCase(fetchDistrictsAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchDistrictsAsync.rejected, (state, action) => {
                state.error = action.error.message || 'Unknown error occurred';
            });
    },
});

export default districtSlice.reducer;

export const selectDistricts = (state: RootState) => state.districts.objects;
export const selectError = (state: RootState) => state.districts.error;
export const selectLoading = (state: RootState) => state.districts.loading;