// Footer.tsx

import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { grey } from '@mui/material/colors';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TikTokIcon from '@mui/icons-material/MusicNote';
import LanguageIcon from '@mui/icons-material/Language';

export const Footer: React.FC = () => {
    return (
        <Box sx={{ bgcolor: "#0f0840", color: grey[50], mt: 6, py: 3 }}>
            <Container maxWidth="lg">
                <Typography variant="body1" align="center">
                    © 2023 FlatWizard UG
                </Typography>
                <Box mt={2} display="flex" justifyContent="center">
                    <Link href="#" color="inherit" sx={{ mx: 1 }}>
                        <TwitterIcon />
                    </Link>
                    <Link href="#" color="inherit" sx={{ mx: 1 }}>
                        <InstagramIcon />
                    </Link>
                    <Link href="#" color="inherit" sx={{ mx: 1 }}>
                        <TikTokIcon />
                    </Link>
                    <Link href="#" color="inherit" sx={{ mx: 1 }}>
                        <LanguageIcon />
                    </Link>
                </Box>
                <Box mt={2}>
                    <Typography variant="body2" align="center">
                        <Link href="#" color="inherit" sx={{ mx: 1 }}>
                            Terms & Conditions
                        </Link> |
                        <Link href="#" color="inherit" sx={{ mx: 1 }}>
                            Privacy Policy
                        </Link> |
                        <Link href="#" color="inherit" sx={{ mx: 1 }}>
                            Imprint
                        </Link>
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};
