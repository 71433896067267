import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Paper, Checkbox, ListItemText, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { FetchListingsParams } from '../../features/listing/listingAPI';
import { useSelector } from 'react-redux';
import { selectSources } from '../../features/source/sourceSlice';
import { selectDistricts } from '../../features/district/districtSlice';
import { District } from '../../features/district/districtAPI';

interface ListingFilterProps {
    onApply: (params: FetchListingsParams) => void;
}

export const ListingFilter: React.FC<ListingFilterProps> = ({ onApply }) => {
    const sources = useSelector(selectSources);
    const districts = useSelector(selectDistricts);
    const initialParams: FetchListingsParams = JSON.parse(localStorage.getItem('filters') || '{}');

    // If sourceNames is not set in local storage, set it to null
    if(!initialParams.sourceNames) initialParams.sourceNames = null
    // If districtIds is not set in local storage, set it to null
    if (!initialParams.districtIds) initialParams.districtIds = null

    const [params, setParams] = useState<FetchListingsParams>(initialParams);

    const handleChange = (filter: keyof FetchListingsParams, value: string) => {
        setParams(prevParams => ({ ...prevParams, [filter]: value !== '' ? Number(value) : null }));
    }

    const handleSourceChange = (filter: keyof FetchListingsParams, value: string[]) => {
        setParams(prevParams => ({ ...prevParams, [filter]: value }));
    }

    const handleDistrictChange = (filter: keyof FetchListingsParams, value: number[]) => {
        setParams(prevParams => ({ ...prevParams, [filter]: value }));
    }

    const applyFilters = () => {
        onApply(params);
    };

    // Create a map of district IDs to names
    const districtMap: { [key: number]: string } = {};
    districts.forEach((district: District) => {
        districtMap[district.id] = district.name;
    });

    useEffect(() => {
        const sourceNames = sources.map(source => source.name);
        if (params.sourceNames === null && sourceNames.length > 0) {
            setParams(prevParams => ({ ...prevParams, sourceNames }))
        }
    }, [sources, params.sourceNames]);

    useEffect(() => {
        const districtIds = districts.map(district => district.id);
        if (params.districtIds === null && districtIds.length > 0) {
            setParams(prevParams => ({ ...prevParams, districtIds }))
        }
    }, [districts, params.districtIds]);

    // useEffect to update local storage whenever filter params change
    useEffect(() => {
        localStorage.setItem('filters', JSON.stringify(params));
    }, [params]);

    return (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Grid container spacing={3}>
                <Grid item xs={2}>
                    <TextField type="number" label="Min Price" value={params.minPrice || ''} onChange={e => handleChange('minPrice', e.target.value)} />
                </Grid>
                <Grid item xs={2}>
                    <TextField type="number" label="Max Price" value={params.maxPrice || ''} onChange={e => handleChange('maxPrice', e.target.value)} />
                </Grid>
                <Grid item xs={2}>
                    <TextField type="number" label="Min Rooms" value={params.minRooms || ''} onChange={e => handleChange('minRooms', e.target.value)} />
                </Grid>
                <Grid item xs={2}>
                    <TextField type="number" label="Max Rooms" value={params.maxRooms || ''} onChange={e => handleChange('maxRooms', e.target.value)} />
                </Grid>
                <Grid item xs={2}>
                    <TextField type="number" label="Min Sqm" value={params.minSqm || ''} onChange={e => handleChange('minSqm', e.target.value)} />
                </Grid>
                <Grid item xs={2}>
                    <TextField type="number" label="Max Sqm" value={params.maxSqm || ''} onChange={e => handleChange('maxSqm', e.target.value)} />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="checkbox-label">Sources</InputLabel>
                        <Select
                            labelId="checkbox-label"
                            id="checkbox-select"
                            multiple
                            value={params.sourceNames || []}
                            onChange={(event) => handleSourceChange('sourceNames', event.target.value as string[])}
                            renderValue={(selected) => selected.join(', ')}
                        >
                            {sources.map((item) => (
                                <MenuItem key={item.id} value={item.name}>
                                    <Checkbox checked={(params.sourceNames || []).indexOf(item.name) > -1} />
                                    <ListItemText primary={item.slug} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="checkbox-label">Districts</InputLabel>
                        <Select
                            labelId="checkbox-label"
                            id="checkbox-select"
                            multiple
                            value={params.districtIds || []}
                            onChange={(event) => handleDistrictChange('districtIds', event.target.value as number[])}
                            renderValue={(selected) => (selected as number[]).map(id => districtMap[id]).join(', ')}
                        >
                            {districts.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    <Checkbox checked={(params.districtIds || []).indexOf(item.id) > -1} />
                                    <ListItemText primary={item.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <Button variant="contained" style={{height: "100%"}} color="primary" onClick={applyFilters} fullWidth>Apply Filters</Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

