import axios from 'axios';

import { ListingResponse } from '../../../../api/src/listings/listing-response.interface'
import { ListingImage } from '../../../../api/src/listing-images/listing-images.interface'

export interface FetchListingsParams {
    page?: number,
    limit?: number,
    districtIds: number[] | null,
    sourceNames: string[] | null,
    minPrice?: number,
    maxPrice?: number,
    minRooms?: number,
    maxRooms?: number,
    minSqm?: number,
    maxSqm?: number,
}


export const fetchListings = async (params: FetchListingsParams) => {
    const defaultParams = {
        page: 1,
        limit: 1000,
    };

    const finalParams = { ...defaultParams, ...params };
    const response = await axios.get<ListingResponse[]>(process.env.REACT_APP_API_ENDPOINT + '/v1/listings/', { params: finalParams });
    return response.data;
};

export const fetchListingImages = async (id: number) => {
    const response = await axios.get<ListingImage[]>(process.env.REACT_APP_API_ENDPOINT + `/v1/listings/${id}/images`);
    return response.data;
}


export type {ListingImage}