import React, { useEffect, useState } from 'react';
import { Typography, Button, Card, CardContent, Table, TableBody, TableRow, TableCell, Modal, Box, Grid, } from '@mui/material';
import { styled } from '@mui/system';
 import { ListingImage, fetchListingImages } from '../../features/listing/listingAPI'; // import the API function

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const StyledCard = styled(Card)(({ theme }) => ({
    // maxWidth: 345,
    margin: theme.spacing(2),
}));

interface ListingDetailsProps {
    listing: any;
    handleClose: () => void;
    open: boolean;
}

export const ListingDetails: React.FC<ListingDetailsProps> = ({ listing, handleClose, open }) => {
    const [images, setImages] = useState<ListingImage[]>([]);
    useEffect(() => {
        // call the API function here
        fetchListingImages(listing.id)
            .then(res => {
                setImages(res);
            })
            .catch(err => {
                console.error(err);
            });
    }, [listing]);

    return (

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 6,
                width: '80%',
                maxHeight: '80%',
                overflow: 'hidden visible'
            }}>
                <Grid container spacing={2}>
                    {images.length > 0 && (<Grid item xs={12} md={6}>
                        <Box sx={{pb: 5}}>
                            <StyledCard>
                                <CardContent>
                                    <Carousel 
                                        className="carousel-wrapper"
                                        dynamicHeight 
                                        centerMode
                                        emulateTouch
                                        showArrows={true}
                                    >
                                        {images.map((image, index) => (
                                            <Box key={index} >
                                                <img src={image.url} style={{ maxHeight: "40vh" }} alt="" />
                                            </Box>
                                        ))}
                                    </Carousel>
                                </CardContent>
                            </StyledCard>
                        </Box>
                    </Grid>)}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ pb: 5, width: "100%" }}>
                            <StyledCard>
                                <CardContent>
                                    <Typography variant="h5" component="h2" gutterBottom>
                                        {listing?.title}
                                    </Typography>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Price:</TableCell>
                                                <TableCell>{listing?.price}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Sqm:</TableCell>
                                                <TableCell>{listing?.sqm}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Rooms:</TableCell>
                                                <TableCell>{listing?.rooms}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">District:</TableCell>
                                                <TableCell>{listing?.districtName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Ortsteil:</TableCell>
                                                <TableCell>{listing?.subDistrictName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Postal Code:</TableCell>
                                                <TableCell>{listing?.postalCode}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Source:</TableCell>
                                                <TableCell>{listing?.sourceName}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">Published At:</TableCell>
                                                <TableCell>{listing?.publishedAt}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    {listing?.url && (
                                        <Button variant="contained" color="primary" href={listing.url} target="_blank" rel="noopener noreferrer" sx={{ mt: 2 }}>
                                            View Listing
                                        </Button>
                                    )}
                                </CardContent>
                            </StyledCard>
                        </Box>
                    </Grid>
                </Grid>
                
            </Box>
        </Modal>
    );
};